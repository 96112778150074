<template>
  <div>
    <note-add-new
      :is-add-new-note-sidebar-active.sync="isAddNewNoteSidebarActive"
      :project-id="projectId"
    />
    <cost-add-new
      :is-add-new-cost-sidebar-active.sync="isAddNewCostSidebarActive"
      :project-id="projectId"
    />
    <sprint-add-new
      :is-add-new-sprint-sidebar-active.sync="isAddNewSprintSidebarActive"
      :project-id="projectId"
    />

    <section class="app-ecommerce-details">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="projectData === undefined">
        <h4 class="alert-heading">Erro ao carregar os dados</h4>
        <div class="alert-body">
          Projeto não encontrado. Tente outro
          <b-link class="alert-link" :to="{ name: 'apps-projects-cards' }">
            Projeto
          </b-link>
          para mais detalhes.
        </div>
      </b-alert>

      <b-overlay
        variant="transparent"
        opacity="1.00"
        blur="2px"
        :show="projectData.length === 0"
        rounded="sm"
      >
        <b-row class="match-height">
          <!-- Content -->

          <b-col xl="8">
            <b-card v-if="projectData" no-body>
              <b-card-body>
                <b-row>
                  <!-- Right: Product Details -->
                  <b-col cols="12" md="12">
                    <b-dropdown
                      id="dropdown-1"
                      class="text-body h4 float-right"
                      size="sm"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                    >
                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="18"
                          class="cursor-pointer"
                        />
                      </template>
                      <b-dropdown-item
                        v-if="restrictions('button_edit_project')"
                        @click="
                          $router.push({
                            name: 'apps-project-edit',
                            params: { id: projectData.id },
                          })
                        "
                      >
                        {{ $t("message.buttons.edit") }}</b-dropdown-item
                      >
                      <b-dropdown-item @click="updateProjectData()">
                        {{ $t("message.buttons.update") }}</b-dropdown-item
                      >
                    </b-dropdown>

                    <!-- Product Name -->
                    <h2>{{ projectData.name }}</h2>
                    <div class="mt-1">
                      <b-badge
                        :variant="
                          badgeColor(
                            projectData.status,
                            projectData.tasksPercentage
                          )
                        "
                      >
                        {{
                          projectStatus(
                            projectData.status,
                            projectData.tasksPercentage
                          )
                        }}
                      </b-badge>
                    </div>

                    <!-- Avability -->
                    <div class="mt-2">
                      <b>{{ $t("message.projects.descriptionProject") }} </b>
                    </div>

                    <!-- Product Description -->

                    <span v-html="projectData.description"></span>
                    <br /><br />

                    <b-row>
                      <b-col cols="12" md="2" style="font-weight: bold">
                        {{ $t("message.projects.startDate") }}
                        <h5>{{ localeDate(projectData.startDate) }}</h5>
                      </b-col>
                      <b-col cols="12" md="2" style="font-weight: bold">
                        {{ $t("message.projects.endDate") }}
                        <h5>{{ localeDate(projectData.endDate) }}</h5>
                      </b-col>
                      <b-col cols="12" md="2" style="font-weight: bold">
                        {{ $t("message.projects.tasks") }}
                        <h5>{{ projectData.tasksQuantity }}</h5>
                      </b-col>
                      <b-col cols="12" md="3" style="font-weight: bold">
                        {{ $t("message.projects.hoursConsumed") }}
                        <span
                          v-if="restrictions('button_timesheet_project')"
                          class="cursor-pointer"
                          @click="showTimeSheetList(projectData.id)"
                          v-b-modal.modal-timeSheet-list
                        >
                          <IconLinkInternal size="12"/>
                        </span>
                        <!-- <feather-icon
                          v-if="restrictions('button_timesheet_project')"
                          icon="ExternalLinkIcon"
                          size="14"
                          class="cursor-pointer"
                          @click="showTimeSheetList(projectData.id)"
                          v-b-modal.modal-timeSheet-list
                        /> -->
                        <h5>{{ projectData.hoursDone }} hora(s)</h5>
                      </b-col>
                      <b-col cols="12" md="3" style="font-weight: bold">
                        {{ $t("message.projects.estimatedHours") }}
                        <h5>{{ projectData.estimatedTime }} hora(s)</h5>
                      </b-col> </b-row
                    ><br />

                    <b-row v-if="restrictions('show_project_cost')">
                      <b-col cols="12" md="12" style="font-weight: bold">
                      <div>
                        <strong>
                          {{ $t("message.contract.totalValue") }}
                        </strong>
                        <feather-icon
                          :icon="isShowTotalValue ? 'EyeIcon' : 'EyeOffIcon' "
                          @click="isShowTotalValue = !isShowTotalValue"
                          class="cursor-pointer"
                        />
                      </div>
                        <h5 v-if="isShowTotalValue">{{ $n(projectCost, "currency") }}</h5>
                         <feather-icon
                          v-else
                          icon="MoreHorizontalIcon"
                          size="24"
                        />
                      </b-col> </b-row
                    ><br v-if="restrictions('show_project_cost')"/>

                    <b-row>
                      <b-col
                        cols="12"
                        md="9"
                        style="font-weight: bold"
                        v-if="projectData.members != null"
                      >
                        {{ $t("message.projects.members") }} ({{
                          projectData.members.length
                        }})
                        <h5 v-if="projectData.members.length === 0">
                          {{ $t("message.projects.memberAssociate") }}
                        </h5>
                        <h5 v-else>
                          {{ projectData.members.join(", ") }}
                        </h5>
                      </b-col>

                      <b-col
                        cols="12"
                        md="3"
                        style="font-weight: bold"
                        v-if="projectData.manager != null"
                      >
                        {{ $t("message.team.managerId") }}
                        <h5 v-if="projectData.manager.length === 0">
                          {{ $t("message.projects.memberAssociate") }}
                        </h5>
                        <h5 v-else>{{ projectData.manager.join(", ") }}</h5>
                      </b-col>
                    </b-row>

                    <br />
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                        style="font-weight: bold"
                        v-if="projectData.sprints != null"
                      >
                       <strong>
                          {{ $t("message.projects.sprints") }} ({{
                            projectData.sprints.length || 0
                          }})
                       </strong>
                       <div class="d-flex">
                        <div
                          v-for="(projData, index) in projectData.sprints"
                          :key="projData.id"
                          class="d-flex"
                        >
                        <div class="d-flex" :class="index !== 0 && 'ml-1'" v-if="projData.status === 'Finished'">
                          <a
                            v-if="projData.status === 'Finished'"
                            @click="openSprintbyId(projData.id)"
                            style="color: #5ca6de; font-weight: normal"
                            ><s>{{ projData.subject }}</s>
                            </a>
                            <IconLinkInternal size="12"/>
                            <!-- <feather-icon icon="ExternalLinkIcon" size="12"/> -->
                            {{ projectData.sprints.length - 1 > index ? ', ' : '' }}
                        </div>
                        <div v-else class="d-flex" :class="index !== 0 && 'ml-1'">
                          <a
                            @click="openSprintbyId(projData.id)"
                            style="color: #5ca6de; font-weight: normal"
                            >
                            {{ projData.subject }}
                            <IconLinkInternal size="12"/>
                            <!-- <feather-icon icon="ExternalLinkIcon" size="12"/> -->
                            {{ projectData.sprints.length - 1 > index ? ', ' : '' }}
                          </a>
                        </div>
                        </div>
                       </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-col>

          <b-col xl="4" md="6">
            <b-card
              v-if="projectData"
              style="cursor: pointer"
              @click="
                $router.push({
                  name: 'apps-projects-kanban',
                  params: { id: projectData.id },
                })
              "
            >
              <div class="d-flex align-items-center justify-content-between">
                <b-card-title class="font-small-4 float-left mb-0">
                  Kanban
                </b-card-title>
                <IconLinkInternal size="12"/>
                <!-- <feather-icon icon="ExternalLinkIcon" size="12"/> -->
              </div>
              <vue-apex-charts
                type="radialBar"
                :height="kanbanChart.height"
                :options="kanbanChartOptions"
                :series="kanbanSeries"
              />
              <br /><br />

              <div class="d-flex justify-content-between">
                <div class="series-info d-flex align-items-center">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                    class="text-warning"
                  />
                  <span class="font-weight-bold text-capitalize ml-75">{{
                    $t("message.tasks.pending")
                  }}</span>
                </div>
                <span>{{ projectData.tasksBacklog }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <div class="series-info d-flex align-items-center">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                    class="text-primary"
                  />
                  <span class="font-weight-bold text-capitalize ml-75">{{
                    $t("message.cards.progress")
                  }}</span>
                </div>
                <span>{{ projectData.tasksProgress }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <div class="series-info d-flex align-items-center">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                    class="text-danger"
                  />
                  <span class="font-weight-bold text-capitalize ml-75">{{
                    $t("message.tasks.rejected")
                  }}</span>
                </div>
                <span>{{ projectData.tasksRejected }}</span>
              </div>
              <div class="d-flex justify-content-between">
                <div class="series-info d-flex align-items-center">
                  <feather-icon
                    icon="CircleIcon"
                    size="16"
                    class="text-success"
                  />
                  <span class="font-weight-bold text-capitalize ml-75">{{
                    $t("message.tasks.finished")
                  }}</span>
                </div>
                <span>{{ projectData.tasksDone }}</span>
              </div>
            </b-card>
          </b-col>

          <b-col lg="4" md="12">
            <b-card text-variant="center" class="card">
              <b-card-title class="font-small-4 float-left">
                {{ $t("message.chart.projectEvolution") }}
              </b-card-title>
              <vue-apex-charts
                type="radialBar"
                :height="radialChart.height"
                :options="radialChartOptions"
                :series="radialSeries"
              /><br />
            </b-card>
          </b-col>

          <b-col lg="4" md="400">
            <b-card text-variant="center" class="card">
              <b-card-title class="font-small-4 float-left">
                Bugs
              </b-card-title>
              <vue-apex-charts
                v-if="pieSeries != [0, 0]"
                type="pie"
                :height="pieChart.height"
                :options="pieChartOptions"
                :series="pieSeries"
              />
              <div v-else>
                <h3>
                  <br /><br /><br /><br /><br />{{ $t("noneDataProjeDatails") }}
                </h3>
              </div>
              <br />
            </b-card>
          </b-col>

          <b-col lg="4" sm="6">
            <b-card text-variant="center" class="card">
              <b-card-title class="font-small-4 float-left">
                {{ $t("message.chart.hoursByTime") }}
              </b-card-title>
              <vue-apex-charts
                type="bar"
                :height="hourChart.height"
                :options="hourChartOptions"
                :series="hourSeries"
              />
            </b-card>
          </b-col>

          <!-- Notes -->
          <b-col
            xl="12"
            md="6"
            v-if="
              projectNoteList.length != 0 && restrictions('notes_card_project')
            "
          >
            <b-card v-if="projectData" no-body>
              <b-table-lite
                responsive
                :items="projectNoteList"
                :fields="projectNoteFields"
              >
                <template #cell(description)="data">
                  <b-card-text class="font-weight-bold mb-25">
                    {{ data.item.userId }}
                  </b-card-text>
                  <b-card-text class="text-nowrap">
                    {{ data.item.description }}
                  </b-card-text>
                </template>
                <template #cell(date)="data">
                  <b-card-text class="text-nowrap">
                    {{ localeDate(data.item.date) }}
                  </b-card-text>
                </template>
              </b-table-lite>
            </b-card>
          </b-col>

          <!-- Costs -->
          <b-col
            xl="12"
            md="6"
            v-if="
              projectCostList.length != 0 && restrictions('costs_card_project')
            "
          >
            <b-card v-if="projectData" no-body>
              <b-table-lite
                responsive
                :items="projectCostList"
                :fields="projectCostFields"
              >
                <template #cell(description)="data">
                  <b-card-text class="font-weight-bold mb-25">
                    {{ data.item.userId }}
                  </b-card-text>
                  <b-card-text class="text-nowrap">
                    {{ data.item.description }}
                  </b-card-text>
                </template>
                <template #cell(value)="data">
                  <b-card-text class="text-nowrap">
                    {{ $n(data.item.value, "currency") }}
                  </b-card-text>
                </template>
                <template #cell(date)="data">
                  <b-card-text class="text-nowrap">
                    {{ localeDate(data.item.date) }}
                  </b-card-text>
                </template>
              </b-table-lite>
            </b-card>
          </b-col>

          <!-- Buttons -->

          <b-col xl="2" md="6">
            <b-button
              @click="isAddNewNoteSidebarActive = true"
              size="sm"
              variant="outline-primary mr-1"
              v-if="restrictions('button_new_project_note')"
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span class="ml-25 align-middle">
                {{ $t("message.projects.newNote") }}</span
              >
            </b-button>
          </b-col>

          <b-col xl="2" md="6">
            <b-button
              @click="isAddNewCostSidebarActive = true"
              size="sm"
              variant="outline-primary mr-1"
              v-if="restrictions('button_new_project_cost')"
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span class="ml-25 align-middle">{{
                $t("message.projects.newAdditionalCost")
              }}</span>
            </b-button>
          </b-col>

          <b-col xl="2" md="6">
            <b-button
              @click="isAddNewSprintSidebarActive = true"
              size="sm"
              variant="outline-primary mr-1"
              v-if="restrictions('button_new_project_cost')"
            >
              <feather-icon icon="PlusIcon" size="12" />
              <span class="ml-25 align-middle">{{
                $t("message.projects.newSprint")
              }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-overlay>
    </section>
    <br />
    <!-- Spacer -->
    <hr class="invoice-spacing" />

    <br />

    <b-row>
      <b-col
        style="max-width: 200px; !important"
        v-for="item in filesData"
        :key="item.id"
      >
        <b-card text-variant="white" class="text-center" bg-variant="primary">
          <feather-icon size="40" icon="FileIcon" />

          <div class="truncate">
            <br />
            <span>{{ item.name }}</span>
          </div>
          <br />
          <b-row>
            <b-col md="12"
              ><b-button
                size="sm"
                @click="downloadFile(item.id, item.name)"
                v-if="restrictions('project_file_download')"
                ><feather-icon size="20" icon="DownloadCloudIcon"
              /></b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-timeSheet-list"
      modal-class="modal-primary"
      centered
      :title="$t('timeSheet')"
      hide-footer
      size="lg"
    >
      <b-table
        id="my-table"
        ref="refauditListTable"
        class="position-relative"
        :items="timeSheetDatailsList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="loadingSpin"
        show-empty
      >
        <template #table-busy>
          <div
            class="text-center my-17"
            style="margin-top: 50px; margin-bottom: 50px"
          >
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ $t("message.load") }}...</strong>
          </div>
        </template>

        <template slot="empty">
          <div class="text-center my-5">
            {{ $t("message.registerNotFound") }}
          </div>
        </template>

        <!-- Column: User -->
        <template #cell(Usuário)="data">
          {{ data.item.userEmail }}
        </template>

        <!-- Column: Hour -->
        <template #cell(Horas)="data">
          {{ data.item.hoursQuantity }}
        </template>
      </b-table>
    </b-modal>

    <!-- Modal -->
  </div>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BImg,
  BCardText,
  BLink,
  BTableLite,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BBadge,
  BModal,
  BOverlay,
  BSpinner,
  BCardTitle,
  BTable,
  BFormFile,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import router from "@/router";
import moment from "moment";
import NoteAddNew from "./NoteAddNew.vue";
import CostAddNew from "./CostAddNew.vue";
import SprintAddNew from "./SprintAddNew.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AccessControl from "@core/utils/access-control";
import VueApexCharts from "vue-apexcharts";
import store from "@/store";
import getHeaderFile from "@core/utils/authFile";
import download from "downloadjs";
import IconLinkInternal from "@core/components/iconLink/IconLinkInternal.vue"

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BTableLite,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BBadge,
    BModal,
    moment,
    NoteAddNew,
    CostAddNew,
    SprintAddNew,
    BOverlay,
    BSpinner,
    BTable,
    BCardTitle,
    VueApexCharts,
    BFormFile,
    BFormGroup,
    IconLinkInternal
  },

  data() {
    return {
    isShowTotalValue: false,
      //Param
      isAddNewNoteSidebarActive: false,
      isAddNewCostSidebarActive: false,
      isAddNewSprintSidebarActive: false,

      //Project id
      projectId: router.currentRoute.params.id,

      //Variables
      projectNoteList: [],
      projectCostList: [],
      projectData: [],

      timeSheetDatailsList: [],

      projectCost: "",
      projectTotalHours: "",

      loadingSpin: false,

      //Fields
      projectNoteFields: [
        { key: "description", label: "Nota" },
        { key: "date", label: "Data" },
      ],
      projectCostFields: [
        ,
        { key: "description", label: "Custo" },
        { key: "value", label: "Valor" },
        { key: "date", label: "Data" },
      ],
      userData: store.state.user.userData,

      //Charts
      radialChart: {},
      radialSeries: [],
      radialChartOptions: {},

      hourChart: {},
      hourSeries: [],
      hourChartOptions: {},

      kanbanChart: {},
      kanbanSeries: [],
      kanbanChartOptions: {},

      pieChart: {},
      pieChartOptions: {},
      pieSeries: [],

      paramData: store.state.user.paramData,

      fileFormats: "",
      fileMaxSize: 0,
      file: null,
      filesData: [],
      selectedFileId: "",
      selectedFileName: "",
    };
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }

      return [
        {
          key: "Usuário",
          sortable: false,
          label: this.getColLabelList("user"),
        },
        { key: "Horas", sortable: false, label: this.getColLabelList("hours") },
      ];
    },
  },

  async created() {
    await axios
      .get(`${URL_API}project_note/${router.currentRoute.params.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => (this.projectNoteList = response.data));

    await axios
      .get(`${URL_API}project_cost/${router.currentRoute.params.id}`, {
        headers: getHeader(this.userData),
      })
      .then((response) => (this.projectCostList = response.data));

    await this.getData();
    await this.loadRadialChartData();
    await this.loadHourChartData();
    await this.loadKanbanChart();
    await this.loadPieChartData();

    await this.getProjectFilesData();

    await this.getProjectCost();
  },

  mounted() {
    this.$root.$on("newProjectNote", (newListNote) => {
      this.projectNoteList = [];
      this.projectNoteList = newListNote;
    });

    this.$root.$on("newProjectCost", (newListCost) => {
      this.projectCostList = [];
      this.projectCostList = newListCost;
    });
  },

  methods: {
    async getProjectFilesData() {
      this.files = [];
      await axios({
        method: "get",
        url: `${URL_API}file/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.filesData = response.data;
      });
    },

    async getProjectCost() {
      await axios
        .get(`${URL_API}chart/project-cost/${router.currentRoute.params.id}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          var string = response.data.chartInfo;

          if (string != null) {
            var data = JSON.parse(string); // Json to object

            this.projectCost = data[0];
            this.projectTotalHours = data[1];
          }
        });
    },

    downloadFile(id, name) {
      axios({
        method: "get",
        url: `${URL_API}file/download/${id}`,
        headers: getHeader(this.userData),
        responseType: "blob",
      }).then((response) => {
        if (response.data.size === 0) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: this.$i18n.t("Error"),
                icon: "XIcon",
                variant: "warning",
                text: this.$i18n.t("offline"),
              },
            });
          } else {
        const content = response.headers["content-type"];
        download(response.data, name, content);
        }
      });
    },

    async loadPieChartData() {
      var vm = this;

      this.pieChart = {
        height: 250,
      };

      this.pieSeries = [
        parseInt(this.projectData.tasksNormal),
        parseInt(this.projectData.tasksBugs),
      ];

      this.pieChartOptions = {
        labels: ["Normal", "Bug"],
        dataLabels: {
          enabled: true,
        },
        plotOptions: {
          //center the dataLabel
          pie: {
            dataLabels: {
              offset: -35,
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                positon: "bottom",
                show: false,
              },
            },
          },
        ],

        colors: ["#00e396", "#d95555", "#feb019"],

        legend: {
          show: false,
          position: "top",
        },
      };
    },

    async loadKanbanChart() {
      var vm = this;

      this.kanbanChart = {
        height: 325,
      };

      var var1 = (
        (100 * this.projectData.tasksDone) /
        this.projectData.tasksQuantity
      ).toFixed(1);
      var var2 = (
        (100 * this.projectData.tasksProgress) /
        this.projectData.tasksQuantity
      ).toFixed(1);
      var var3 = (
        (100 * this.projectData.tasksRejected) /
        this.projectData.tasksQuantity
      ).toFixed(1);
      var var4 = (
        (100 * this.projectData.tasksBacklog) /
        this.projectData.tasksQuantity
      ).toFixed(1);

      if (isNaN(parseFloat(var1))) {
        var1 = 0;
      }
      if (isNaN(parseFloat(var2))) {
        var2 = 0;
      }
      if (isNaN(parseFloat(var3))) {
        var3 = 0;
      }
      if (isNaN(parseFloat(var4))) {
        var4 = 0;
      }

      this.kanbanSeries = [var1, var2, var3, var4];

      this.kanbanChartOptions = {
        labels: [
          vm.getColLabel("done"),
          this.getColLabel("progress"),
          this.getColLabel("rejected"),
          "Backlog",
        ],
        colors: ["#30c975", "#9d94f4", "#ec6667", "#faaf67"],
        plotOptions: {
          radialBar: {
            size: 150,
            hollow: {
              size: "15%",
            },
            track: {
              strokeWidth: "100%",
              margin: 15,
            },
            dataLabels: {
              value: {
                fontSize: "1rem",
                colors: "#5e5873",
                fontWeight: "500",
                offsetY: 5,
              },
              total: {
                show: true,
                label: "Total",
                fontSize: "1.286rem",
                colors: "#5e5873",
                fontWeight: "500",

                formatter() {
                  return vm.projectData.tasksQuantity;
                },
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        chart: {
          height: 355,
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
          },
        },
      };
    },

    async loadHourChartData() {
      this.hourChart = {
        height: 250,
      };

      this.hourSeries = [
        {
          name: "Lançamento de horas",
          data: [this.projectData.hoursDone],
        },
        {
          name: "Horas estimadas",
          data: [this.projectData.estimatedTime],
        },
      ];

      this.hourChartOptions = {
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        chart: {
          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#887ef2", "#ff9f43"],

        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: (val) => {
            return val + "h";
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: -35,
          offsetY: 0,
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },

        tooltip: {
          followCursor: true,
          y: {
            formatter: (value) => {
              return value + "h";
            },
          },
        },

        legend: {
          position: "top",
        },

        xaxis: {
          categories: [""],
          labels: {
            show: true,
            formatter: (value) => {
              return value + "h";
            },
          },
        },
      };
    },

    async loadRadialChartData() {
      var vm = this;

      var var1 = [this.projectData.tasksPercentage];

      if (isNaN(parseFloat(var1))) {
        var1 = [0];
      }

      this.radialSeries = [var1];

      this.radialChart = {
        height: 350,
      };

      this.radialChartOptions = {
        colors: ["#6a91b1"],

        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true,
          },

          animations: {
            enabled: true,
            easing: "easeinout",
            speed: 800,
          },
        },

        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#e7e7e7",
              strokeWidth: "97%",
              margin: 5,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -2,
                fontSize: "22px",
              },
            },
          },
        },
        grid: {
          padding: {
            top: -10,
          },
        },
        labels: [vm.getColLabelChart("evolution")],
      };
    },

    restrictions(value) {
      return AccessControl(value);
    },

    getColLabelChart(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    async showTimeSheetList(id) {
      this.loadingSpin = true;
      await axios({
        method: "get",
        url: `${URL_API}timeSheet/list-details/` + id,
        headers: getHeader(this.userData),
      }).then((response) => {
        this.timeSheetDatailsList = response.data;
        this.loadingSpin = false;
      });
    },

    openSprintbyId(sprintId) {
      this.$router.push({ name: "apps-sprint-view", params: { id: sprintId } });
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    badgeColor(value, percentage) {
      if (value === "_PENDING") {
        return "secondary";
      }
      if (value === "_RUNNING") {
        return "primary";
      }
      if (value === "_ENDED" || percentage === 100) {
        return "success";
      }
      if (value === "_LATE" && percentage != 100) {
        return "danger";
      }
      if (value === "_CLOSED") {
        return "warning";
      }
      return "primary";
    },

    projectStatus(value, percentage) {
      if (value === "_PENDING") {
        return this.getColLabel("pending");
      }
      if (value === "_RUNNING") {
        return this.getColLabel("running");
      }
      if (value === "_ENDED" || percentage === 100) {
        return this.getColLabel("ended");
      }
      if (value === "_LATE" && percentage != 100) {
        return this.getColLabel("late");
      }
      if (value === "_CLOSED") {
        return this.getColLabel("closed");
      }
      return "Não informado";
    },

    async getData() {
      await axios({
        method: "get",
        url: `${URL_API}project/details-dto/${router.currentRoute.params.id}`,
        headers: getHeader(this.userData),
      })
        .then((response) => {
          this.projectData = response.data;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            this.projectData = undefined;
          }
        });
    },

    getColLabel(colName) {
      return this.$i18n.t("message.cards." + colName);
    },

    getColLabelList(colName) {
      return this.$i18n.t("message.list." + colName);
    },

    updateProjectData() {
      axios({
        method: "put",
        url: `${URL_API}project/update/${this.projectData.id}`,
        headers: getHeader(this.userData),
      })
        .then((response) => (this.projectData = response.data))
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.saved"),
              icon: "CheckIcon",
              variant: "success",
              text: this.$i18n.t("message.toast.updated"),
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

h5 {
  font-weight: normal;
}
</style>
