<template>
  <b-sidebar
    id="add-new-note-sidebar"
    :visible="isAddNewNoteSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-note-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ $t("message.projects.newNote") }}</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewnote())"
          @reset.prevent="resetForm"
        >
          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="nota"
            rules="required"
          >
            <b-form-group
              label-for="description"
              :state="getValidationState(validationContext)"
            >
              <b-form-textarea
                rows="3"
                id="description"
                v-model="noteData.description"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ $t("message.buttons.create") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.create") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewNoteSidebarActive",
    event: "update:is-add-new-note-sidebar-active",
  },
  props: {
    isAddNewNoteSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,

      userData: store.state.user.userData,
    };
  },

  methods: {
    submitNewnote() {
      this.noteData.date = new Date();
      this.noteData.projectId = this.projectId;
      this.noteData.userId = this.userData.id;

      axios({
        method: "post",
        url: `${URL_API}project_note`,
        headers: getHeader(this.userData),
        data: this.noteData,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: this.$i18n.t("message.toast.create"),
              icon: "CheckIcon",
              variant: "success",
              text: `Nota criada com sucesso`,
            },
          });
        })
        .then(() => {
          axios
            .get(`${URL_API}project_note/${this.projectId}`, {
              headers: getHeader(this.userData),
            })
            .then((response) => {
              this.$root.$emit("newProjectNote", response.data);
              this.onSubmit();
            });
        });
    },
  },

  setup(props, { emit }) {
    const blanknoteData = {};

    const noteData = ref(JSON.parse(JSON.stringify(blanknoteData)));
    const resetnoteData = () => {
      noteData.value = JSON.parse(JSON.stringify(blanknoteData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-note-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetnoteData);

    return {
      noteData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-note-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
